/* eslint-disable react-hooks/exhaustive-deps */
import CircularProgress from '@material-ui/core/CircularProgress';
import { CompassUserContext } from '@orientaction/Context';
import { useContext, useEffect, useState } from 'react';
import NoRattacher from './NoRattacher';
import Rattacher from './Rattacher';
import style from './style';
import { apiServiceNative } from '../../utils/fetchNative';

const rattachedBenificiaryServiceIds = [1, 5];

const MyResources = () => {
  const { userInfos, loading } = useContext<any>(CompassUserContext);
  const [loading1, setLoading] = useState(false);
  const [canAccess, setCanAccess] = useState<boolean>(false);

  const classes = style();

  const getListGame = async () => {
    setLoading(true);
    const response = await apiServiceNative.get('/api/game-list-user');
    console.log({ response });
    const check =
      (userInfos?.rattachement &&
        rattachedBenificiaryServiceIds.includes(userInfos?.service?.id)) ||
      (response && response.length > 0);
    console.log({check, userInfos, response});
    setCanAccess(check);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      if (userInfos) {
        await getListGame();
      }
      // console.log({ response });
    })();
  }, [userInfos]);

  if (loading || loading1) {
    return (
      <div className={classes.bodycenter}>
        <CircularProgress />
      </div>
    );
  }
  // only rattached user and users within competences service could access resources
  // const canAccess =
  //   (userInfos?.rattachement && rattachedBenificiaryServiceIds.includes(userInfos?.service?.id)) ||
  //   false;

  return <>{canAccess ? <Rattacher /> : <NoRattacher />}</>;
};

export default MyResources;
